import React from "react"
import { Link } from "gatsby"
import Image from "@/components/general/Image"

// import Image from "@/components/image"
import SEO from "@/components/seo"
import Opener from "@/components/general/Opener"
import Sizing from "@/components/general/Sizing"

import Heading from "@/components/general/Heading"
import RacismTable from "@/components/graphs/RacismTable"
import ColorChangeBG from "@/components/general/ColorChangeBG"
import Spacing from "@/components/general/Spacing"
import Signup from "@/components/general/Signup"
import Dropcap from "@/components/general/Dropcap"
import FooterNav from "@/components/general/FooterNav"

import Motif from "@/components/general/Motif"
import Footer from "@/components/general/Footer"
import Share from "@/components/general/Share"

import openerText from "@/images/racism/opener-text.png"
import openerTextSml from "@/images/racism/opener-text-sml.png"
import shareImg from "@/images/og/og_racism.jpg"

import dcc from "@/images/racism/dcc.png"
import dct from "@/images/racism/dct.png"
import dcr from "@/images/racism/dcr.png"

import SignupPopup from "@/components/general/SignupPopup"

const RacismPage = ({pageContext}) => {
    return (
        <>
            <SEO
                page="/racism"
                title="Free Markets Destroy | Learn How Free Markets Destroy Racism"
                description="Capitalism can help solve one of humanities most enduring challenges, accelerating social change by flowing past the artificial barriers that racism creates."
                img={shareImg}
            />

            <Opener
                pageName="racism"
                titles={[
                    { img: openerText, size: 14 },
                    { img: openerTextSml, size: 22 },
                ]}
            />

            {
                // ======================================= SECTION 1 =======================================
            }

            <Sizing
                styles={{ float: "right" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginRight: 2,
                            width: 20,
                            marginBottom: 0,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginRight: 2,
                            width: 10,
                            // marginBottom: 1,
                        },
                    },
                    // { min: 1200, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <div className="copy--lead">
                    <Dropcap img={dcc}>C</Dropcap>apitalism is an inherently
                    racist system, designed by white men to empower white men,
                    right? <br />
                    <br />
                    Wrong. <br />
                    <br />
                    Over and over capitalism has proven to be a powerful force
                    AGAINST racism. And one of the greatest examples of this is
                    a simple mail-order catalog.
                </div>
            </Sizing>

            <Sizing
                styles={{ float: "left" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: 2,
                            width: 22,
                            marginBottom: 1,
                            marginTop: 1,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginLeft: 2,
                            width: 22,
                            marginTop: -3,
                            marginBottom: 1,
                        },
                    },
                    // { min: 1200, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <Image
                    src={"racism_cornucopia"}
                    style={{ width: "100%" }}
                ></Image>
            </Sizing>
            {/*
            <div style={{ float: "left", clear: "left" }}>
                <SignupPopup></SignupPopup>
            </div> */}

            <Sizing
                styles={{ float: "left" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginTop: -4,
                            width: 24,
                        },
                    },

                    {
                        min: 1024,
                        val: {
                            marginTop: -16,
                            width: 24,
                        },
                    },
                ]}
            >
                <ColorChangeBG
                    from={{ r: 255, g: 255, b: 255 }}
                    to={{ r: 233, g: 146, b: 69 }}
                >
                    <Sizing
                        styles={{
                            float: "left",
                            position: "relative",
                            zIndex: 4,
                        }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    marginLeft: 2,
                                    width: 16,
                                    marginBottom: 1,
                                },
                            },
                            {
                                min: 1024,
                                val: {
                                    marginLeft: 2,
                                    width: 10,
                                    marginTop: 1,
                                    marginBottom: 2,
                                },
                            },
                            {
                                min: 1200,
                                val: {
                                    marginLeft: 2,
                                    width: 7,

                                    marginBottom: 2,
                                },
                            },
                            // { min: 1200, val: { marginLeft: 8, width: 10 } },
                        ]}
                    >
                        <p className="copy">
                            In the Jim Crow South, racist laws forced rural
                            black families into sharecropping. This brutal
                            arrangement required them to work someone else's
                            land for low pay, leaving these families unable to
                            buy anything without credit.
                        </p>

                        <p className="copy">
                            Local stores would often deny credit for items
                            deemed “too white” such as luxury items. Or stores
                            would simply ban black customers from their stores
                            entirely. How could southern black families possibly
                            skirt racist laws and get access to the items they
                            needed to run their lives?
                        </p>

                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        bottom: -2,
                                        right: -5,
                                        width: 1.5,
                                    },
                                },
                            ]}
                        >
                            <Motif page="racism" id="motif_3" movement={0.02} />
                        </Sizing>

                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        bottom: -3,
                                        right: -4.5,
                                        width: 1.3,
                                    },
                                },
                            ]}
                        >
                            <Motif
                                page="racism"
                                id="motif_2"
                                movement={-0.015}
                            />
                        </Sizing>
                    </Sizing>

                    <div style={{ float: "left", clear: "left" }}>
                        <SignupPopup></SignupPopup>
                    </div>

                    <Sizing
                        styles={{ float: "left", clear: "left" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    marginLeft: 2,
                                    width: 22,
                                    marginBottom: 1,
                                    marginTop: 1,
                                },
                            },
                            {
                                min: 1024,
                                val: {
                                    marginLeft: 3,
                                    width: 5,
                                    marginTop: 2,
                                    marginBottom: 1,
                                },
                            },
                            // { min: 1200, val: { marginLeft: 8, width: 10 } },
                        ]}
                    >
                        <Image
                            src={"racism_sowell"}
                            style={{ width: "100%" }}
                        ></Image>

                        <Sizing
                            styles={{ float: "left" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        width: 20,
                                        marginTop: 1,
                                    },
                                },
                                {
                                    min: 1024,
                                    val: {
                                        width: 5,
                                        marginTop: 1,
                                    },
                                },
                                // { min: 1200, val: { marginLeft: 8, width: 10 } },
                            ]}
                        >
                            <Image
                                src={"racism_sowellquote"}
                                style={{ width: "100%" }}
                            ></Image>
                        </Sizing>

                        <Sizing
                            styles={{ float: "left" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        width: 22,
                                        marginTop: 0.5,
                                    },
                                },
                                {
                                    min: 1024,
                                    val: {
                                        width: 5,
                                        marginTop: 0.5,
                                    },
                                },
                                // { min: 1200, val: { marginLeft: 8, width: 10 } },
                            ]}
                        >
                            <span className="caption">- Thomas Sowell</span>
                        </Sizing>
                    </Sizing>

                    <Sizing
                        styles={{ float: "right", position: "relative" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    marginRight: 2,
                                    width: 20,
                                    marginBottom: 1,
                                },
                            },
                            {
                                min: 1024,
                                val: {
                                    marginRight: 2,
                                    width: 10,
                                    marginTop: 0,
                                },
                            },
                            {
                                min: 1200,
                                val: {
                                    marginRight: 5,
                                    width: 9,
                                    marginTop: 4,
                                },
                            },
                            // { min: 1200, val: { marginLeft: 8, width: 10 } },
                        ]}
                    >
                        <p className="copy--lead">
                            It turns out, racism was costing brick and mortar
                            stores money. Store owners were artificially
                            suppressing their own sales when they turned away
                            black customers. As the famed economist Thomas
                            Sowell explained, “their bigotry came at a cost.”
                        </p>

                        <p className="copy">
                            Jim Crow laws and practices created a huge,
                            underserved customer base, unintentionally
                            generating an enormous market opportunity. Black
                            Americans were earning money and they wanted to
                            spend it on high quality goods.
                        </p>
                        <p className="copy">
                            Enter Richard Sears, the Jeff Bezos of his day.
                            Before Amazon or Walmart, Sears was the biggest name
                            in American retail. Richard Sears actually came up
                            with an ingenious solution for skirting racist laws:
                            the Sears mail order catalog.
                        </p>
                        <p className="copy">
                            Popularized in the 1900s, the catalog gave black
                            families a way to buy high quality goods on credit
                            without ever encountering racist laws or store
                            owners. Hundreds of pages, free rural delivery and
                            special credit lines were available specifically for
                            black patrons.
                        </p>

                        <Sizing
                            styles={{ position: "absolute", zIndex: 0 }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        bottom: 0,
                                        right: -3,
                                        width: 1,
                                    },
                                },
                            ]}
                        >
                            <Motif page="racism" id="motif_1" movement={0.03} />
                        </Sizing>
                    </Sizing>
                </ColorChangeBG>
            </Sizing>
            <div
                style={{
                    width: "100%",
                    backgroundColor: "rgb(233,146, 69)",
                    float: "left",
                }}
            >
                <Image src={"racism_bbking"} style={{ width: "100%" }}></Image>
            </div>
            <ColorChangeBG
                from={{ r: 233, g: 146, b: 69 }}
                to={{ r: 104, g: 197, b: 184 }}
            >
                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginTop: 1,
                                marginLeft: 2,
                                width: 20,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 3,
                                width: 8,
                                marginTop: 1,
                                marginBottom: 0,
                            },
                        },
                        {
                            min: 1200,
                            val: {
                                marginLeft: 4,
                                width: 9,
                                marginTop: 1,
                                marginBottom: 0,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <p className="copy">
                        <Dropcap img={dct}>T</Dropcap>he impact of the catalog
                        was particularly noticeable on the development of black
                        music. In 1908, the catalog started selling steel string
                        guitars for less than two dollars. Sales of those
                        guitars were crucial to the development of the Delta
                        blues. Blues legend B. B. King learned how to play on
                        strings on a guitar he bought from Sears.
                    </p>
                    <p className="copy">
                        The catalog quickly became the main way black customers
                        accessed high end goods and it evolved as local racist
                        politicians and companies tried to crack down, going so
                        far as to burn the catalogs in the streets. Sears even
                        gave customers instructions on how to get around racist
                        post office staffers who refused to sell buyers the
                        stamps they needed to mail their orders.
                    </p>
                    <p className="copy">
                        With every purchase, Sears was reinforcing the notion
                        that even if someone doesn't look like you, they're
                        still worth serving, still worth helping.
                    </p>
                    <p className="copy">
                        Discrimination excludes customers. This creates a strong
                        incentive for entrepreneurs to serve the new market
                        created by racist laws or practices. It is a dynamic at
                        work all the time in the open market.
                    </p>
                    <p className="copy">
                        In the landmark Supreme Court case, Plessy v. Ferguson,
                        profit hungry railroads joined forces with civil rights
                        groups to legally challenge segregation laws because the
                        artificial seat vacancies were costing them money.
                    </p>
                    <p className="copy">
                        Or look at present-day ride share apps like Uber. Uber
                        made discrimination impossible, fueling the app's
                        explosion.
                    </p>
                </Sizing>
                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 4,
                                width: 18,
                                marginBottom: 3,
                                marginTop: 4,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 2,
                                width: 4,
                                marginTop: 1,

                                minWidth: "350px",
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <Signup color={["#68C5B8", "#e99245"]}></Signup>
                </Sizing>

                <Sizing
                    styles={{
                        float: "left",
                        clear: "left",
                        position: "relative",
                    }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginLeft: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginTop: 4,
                                marginLeft: 4,
                                width: 12,
                            },
                        },
                    ]}
                >
                    <Sizing
                        styles={{ position: "absolute", zIndex: 0 }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    top: -5,
                                    right: -6,
                                    width: 4,
                                },
                            },
                        ]}
                    >
                        <Motif page="racism" id="motif_6" movement={0.03} />
                    </Sizing>
                    <Sizing
                        styles={{ position: "absolute", zIndex: 0 }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    top: -1,
                                    right: -6,
                                    width: 2,
                                },
                            },
                        ]}
                    >
                        <Motif page="racism" id="motif_10" movement={-0.02} />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute", zIndex: 0 }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    top: 0,
                                    left: 5.5,
                                    width: 2,
                                },
                            },
                        ]}
                    >
                        <Motif page="racism" id="motif_5" movement={-0.01} />
                    </Sizing>

                    <div style={{ position: "realtive", zIndex: 1 }}>
                        <Heading navy="true">
                            Destroying Systemic Racism with Education
                        </Heading>
                    </div>

                    <Sizing
                        styles={{ position: "absolute", zIndex: 2 }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    bottom: 0,
                                    right: 0,
                                    width: 1,
                                },
                            },
                        ]}
                    >
                        <Motif page="racism" id="motif_3" movement={0.02} />
                    </Sizing>
                </Sizing>

                <Sizing
                    styles={{ float: "right", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginRight: 2,
                                marginTop: 2,
                                marginBottom: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 3,
                                width: 10,
                            },
                        },
                    ]}
                >
                    <Sizing
                        styles={{ position: "absolute", zIndex: 2 }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    top: 3,
                                    left: -9,
                                    width: 4,
                                },
                            },
                        ]}
                    >
                        <Motif page="racism" id="motif_7" movement={-0.02} />
                    </Sizing>
                    <Sizing
                        styles={{ position: "absolute", zIndex: 2 }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    bottom: 3,
                                    left: -5,
                                    width: 3,
                                },
                            },
                        ]}
                    >
                        <Motif page="racism" id="motif_9" movement={0.02} />
                    </Sizing>
                    <p className={"copy--lead"}>
                        <Dropcap img={dcr}>R</Dropcap>
                        acism still exists, and in fact, it is government and
                        special interest groups that are still leading the
                        charge.
                    </p>

                    <p className={"copy"}>
                        This example is front and center in America’s public
                        schooling system. School bureaucrats, politicians and
                        union bosses stick up for a system that sends minority
                        children to failing schools, many in inner cities. They
                        reject opportunities for school choice even though
                        minority students and families are the ones who would
                        benefit most. These opponents of the improved
                        educational outcomes of minority children know full-well
                        that the research is clear.
                    </p>

                    <p className={"copy"}>
                        You needn’t look further than Stanford University’s
                        CREDO study. It analyzed charter school outcomes across
                        27 states and found negative effects for some white
                        students, with positive effects for black and Hispanic
                        students in poverty. Black students who attended charter
                        schools gained 36 days of math and 29 days of reading.
                        Hispanic students gained 43 additional days of reading
                        and 50 days of math.
                    </p>
                </Sizing>
            </ColorChangeBG>

            <RacismTable></RacismTable>

            <Sizing
                styles={{ float: "right", position: "relative" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            width: 20,
                            marginRight: 2,
                            marginTop: 2,
                            marginBottom: 2,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginRight: 3,
                            width: 10,
                        },
                    },
                ]}
            >
                <Sizing
                    styles={{ position: "absolute", zIndex: 2 }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                top: -3,
                                left: -9,
                                width: 3,
                            },
                        },
                    ]}
                >
                    <Motif page="racism" id="motif_9" movement={-0.03} />
                </Sizing>
                <Sizing
                    styles={{ position: "absolute", zIndex: 2 }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                top: 0,
                                left: -7,
                                width: 2.5,
                            },
                        },
                    ]}
                >
                    <Motif page="racism" id="motif_10" movement={-0.06} />
                </Sizing>
                <Sizing
                    styles={{ position: "absolute", zIndex: 2 }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                top: 3,
                                left: -5,
                                width: 2,
                            },
                        },
                    ]}
                >
                    <Motif page="racism" id="motif_11" movement={-0.1} />
                </Sizing>

                <p className={"copy"}>
                    It was even more clear in urban areas – Black students
                    gained 59 days of math and 44 days of reading. Jim Crow laws
                    might be gone but there are plenty in positions of power who
                    stand against the educational advancements of minority
                    children.
                </p>
                <p className={"copy"}>
                    Free market capitalism provides information and opportunity
                    that creates incentives for us to serve each other –
                    regardless of how we look or what we believe. In other
                    words, free markets destroy racism.
                </p>
            </Sizing>

            <Sizing
                style={{
                    width: "100%",
                    float: "left",
                }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginBottom: 2,
                        },
                    },
                ]}
            >
                <Image src={"racism_happy"} style={{ width: "100%" }}></Image>
            </Sizing>

            <Sizing
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: 20,
                            maxWidth: "300px",
                            height: "50px",
                            marginTop: 2,
                            paddingBottom: 5,
                            position: "relative",
                            display: "block",
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            display: "none",
                        },
                    },
                ]}
            >
                <Share></Share>
            </Sizing>

            <Spacing space="0" />
            <FooterNav next={"disease"} previous={"boredom"} langKey={pageContext.langKey}/>
            <Footer></Footer>
        </>
    )
}

export default RacismPage
