import React, { useRef, useEffect } from "react"
import styles from "../../styles/racism-table.module.scss"
import Sizing from "../general/Sizing"

const RacismTable = () => {
    return (
        <div className={styles.wrap}>
            <div className={styles.main}>
                <div className={styles.waveTop}>
                    <svg
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1200 120"
                        preserveAspectRatio="none"
                    >
                        <path d="M321.39 56.44c58-10.79 114.16-30.13 172-41.86 82.39-16.72 168.19-17.73 250.45-.39C823.78 31 906.67 72 985.66 92.83c70.05 18.48 146.53 26.09 214.34 3V0H0v27.35a600.21 600.21 0 00321.39 29.09z"></path>
                    </svg>
                </div>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginLeft: 2,
                                marginTop: 2,
                                marginBottom: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginTop: 0,
                                width: 6,
                                marginLeft: 2,
                                marginBottom: 0,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <h2 className={"copy copy--lead copy--white"}>
                        Summary of Significant Charter Impacts by Student Group
                    </h2>

                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    marginTop: 1,
                                },
                            },
                        ]}
                    >
                        <a
                            className={styles.link}
                            href="https://credo.stanford.edu/"
                            target="_blank"
                        >
                            credo.stanford.edu
                        </a>
                    </Sizing>
                </Sizing>

                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginRight: 2,
                                marginTop: 2,
                                marginBottom: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginTop: 0,
                                width: 12,
                                marginRight: 2,
                                marginBottom: 0,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <table>
                        <tbody>
                            <tr>
                                <th scope="col">STUDENT GROUP</th>
                                <th scope="col">READING</th>
                                <th scope="col">MATH</th>
                            </tr>

                            <tr>
                                <td>White</td>
                                <td className={styles.red}>Negative</td>
                                <td className={styles.red}>Negative</td>
                            </tr>
                            <tr>
                                <td>Black Poverty</td>
                                <td className={styles.green}>Positive</td>
                                <td className={styles.green}>Positive</td>
                            </tr>
                            <tr>
                                <td>Black Non Poverty</td>
                                <td>Similar</td>
                                <td>Similar</td>
                            </tr>
                            <tr>
                                <td>Hispanic Poverty</td>
                                <td className={styles.green}>Positive</td>
                                <td className={styles.green}>Positive</td>
                            </tr>
                            <tr>
                                <td>Hispanic Non Poverty</td>
                                <td className={styles.red}>Negative</td>
                                <td className={styles.red}>Negative</td>
                            </tr>
                            <tr>
                                <td>
                                    Hispanic ELL (English Language Learners)
                                </td>
                                <td className={styles.green}>Positive</td>
                                <td className={styles.green}>Positive</td>
                            </tr>
                            <tr>
                                <td>
                                    Hispanic Non ELL (English Language Learners)
                                </td>
                                <td className={styles.green}>Positive</td>
                                <td>Similar</td>
                            </tr>
                            <tr>
                                <td>Asian</td>
                                <td>Similar</td>
                                <td className={styles.red}>Negative</td>
                            </tr>
                            <tr>
                                <td>Students in Poverty </td>
                                <td className={styles.green}>Positive</td>
                                <td className={styles.green}>Positive</td>
                            </tr>
                            <tr>
                                <td>English Language Learners</td>
                                <td className={styles.green}>Positive</td>
                                <td className={styles.green}>Positive</td>
                            </tr>
                            <tr>
                                <td>Special Education </td>
                                <td>Similar</td>
                                <td className={styles.green}>Positive</td>
                            </tr>
                        </tbody>
                    </table>
                </Sizing>

                <div className={styles.clear}></div>

                <div className={styles.waveBottom}>
                    <svg
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1200 120"
                        preserveAspectRatio="none"
                    >
                        <path d="M321.39 56.44c58-10.79 114.16-30.13 172-41.86 82.39-16.72 168.19-17.73 250.45-.39C823.78 31 906.67 72 985.66 92.83c70.05 18.48 146.53 26.09 214.34 3V0H0v27.35a600.21 600.21 0 00321.39 29.09z"></path>
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default RacismTable
